<template>
  <v-container class="cw-kreditz">
    <v-row
      align="start"
      class="cw-page__title"
    >
      <v-col
        class="pr-4"
        cols="auto"
      >
        <v-icon class="mt-1">
          person_outline
        </v-icon>
      </v-col>
      <v-col class="pl-0">
        <h1 class="text-h5">
          <translate>
            Verification of solvency
          </translate>
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="pt-1">
        <p>
          <translate>
            We strongly support good lending practices and check the solvency of each loan
            applicant through the Kreditz service. The use of the service is based on the PSD2
            Payment Services Directive and allows us to quickly provide you with a loan that suits
            your ability to pay back the loan. The review usually takes less than 30 seconds,
            after which you will receive a loan offer immediately.
          </translate>
          <a
            id="cw-kreditz--info"
            class="ml-1"
            href="#"
            @click.prevent.stop="
              $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                component: 'DialogKreditz',
              });
              $eventLogger.clickEvent($event);
            "
          >
            <translate>More information</translate>
          </a>
        </p>

        <v-alert
          border="left"
          type="warning"
          dense
          text
        >
          <translate>
            Antivirus protection may cause a problem when using Kreditz that prevents the connection
            to Kreditz. You can temporarily turn off the antivirus protection you are using to
            complete the Kreditz check.
          </translate>
        </v-alert>

        <v-alert
          border="left"
          type="info"
          dense
          text
        >
          <translate>
            From the banks below, select where your earnings are coming from and identify with your
            online banking ID.
          </translate>
        </v-alert>
        <div class="cw-kreditz__wrapper">
          <iframe id="cw-kreditz__iframe" :src="url" class="cw-kreditz__iframe"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwKreditz',

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
    }),
    url() {
      const clientId = 'client_id=70';
      const market = 'market=SE';
      const locale = 'locale=se';
      const iframe = 'is_iframe=true';
      const url = process.env.VUE_APP_KREDITZ_SERVER_URL;
      const env = process.env.VUE_APP_KREDITZ_ENV;
      const caseId = `case_id=${this.$route.params.hashId}`;
      const envParam = env ? `env=${env}&` : '';

      return `${url}?${envParam}${caseId}&${market}&${clientId}&${locale}&${iframe}`;
    },
  },

  mounted() {
    this.startPolling();
  },

  methods: {
    ...mapActions({
      getApplication: 'application/loadLoanApplication',
    }),

    changePage() {
      DataLayer.solvencyCheckFinished();

      this.$router.push({ name: 'changePage' });
    },

    startPolling() {
      const sleep = time => new Promise(resolve => setTimeout(resolve, time));
      const poll = async (time) => {
        await this.getApplication();
        if (this.initialData.currentPage && this.initialData.currentPage !== 'kreditz') {
          this.changePage();
          return;
        }
        await sleep(time);
        await poll(time);
      };
      poll(1000);
    },
  },
};
</script>

<style lang="scss">
.cw-kreditz {
  &__wrapper {
    padding: 16px 0;
    border-radius: 4px;
    background: rgb(245, 248, 249);
  }
  &__iframe {
    border: none;
    height: 600px;
    width: 100%;
  }
}
</style>
